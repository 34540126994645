.d-flex {
    display: flex;
}

.imageUpload {
    width: 30%;
}

.input-feild {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    margin-bottom: 10px;
}

.input-feild > div {
    width: 48%;
}
.imageUpload img {
    width: 80%;
}

.input-feild > div input {
    width: calc(100% - 10px);
    padding: 7px 5px;
    border: 0px;
    border: 1px solid #d3d3d3;
    background: #ffffffd4;
    border-radius: 3px;
}

input[type="submit"] {
    font-size: 16px;
    padding: 8px 16px;
    margin-left: auto;
    display: block;
    background: green;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 6px;
    text-transform: uppercase;
    margin-top: 20px;
}

.modules ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
}

.modules ul li {
    width: 20%;
    margin-bottom: 20px;
}
.modules {
    background: #efefef;
    padding: 1px 20px 30px;
    margin-top: 30px;
    border-radius: 6px;
}

.form {
    margin-top: 30px;
}
.imageUpload .uploadImageBlock img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: #fff;
}
input[type="submit"][disabled] {
    display: none;
}
.imageUpload .uploadImageBlock input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.uploadImageBlock {
    position: relative;
    width: 80%;
}
.uploadImageBlock{
    background: url(../images/dummy-logo.png)no-repeat;
    height: 150px; 
    background-size: contain;
    background-position: center;
}
.uploadImageBlock .icon {
    right: 0;
    position: absolute;
    bottom: 0;
    background: #c9c9c9;
    width: 20px;
    height: 20px;
    padding: 9px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.checkbox-container {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
    vertical-align: middle;
}
  
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkmark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #cbcbcb;
    border: 1px solid #8d8d8d;
    border-radius: 3px;
    margin-right: 10px;
  }
  
  .checkbox-container input:checked + .checkmark:after {
    content: '';
    position: absolute;
    display: block;
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .checkbox-container:hover .checkmark {
    background-color: #f2f2f2;
  }
  .justify-space-between{
    justify-content: space-between;
  }
  .removeBtn {
    position: absolute;
    right: 0;
    top: 0;
    width: auto !important;
    /* background: #ddd; */
    /* padding: 1px 5px; */
    border-radius: 50%;
    cursor: pointer;
} 


.userPage .addFeild, .userPage .removeBtn,.userPage input[type="file"],.userPage .uploadImageBlock .icon {
    display: none;
}


.userPage.active .addFeild, .userPage.active .removeBtn,.userPage.active input[type="file"], .userPage.active .uploadImageBlock .icon {
    display: block;
}


