@import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100..900&display=swap');

body, html{
    font-family: "Vazirmatn", sans-serif;
    overflow: hidden;
}
a {
    text-decoration: none;
}

form.form-sec {
    width: 400px;
    margin: auto;
    background: #f1f1f1;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px 0px #ddd;
}

form.form-sec input {
    padding: 11px;
    width: calc(100% - 22px);
    margin-bottom: 20px;
    background: #fff !important;
    border-radius: 4px;
}

form.form-sec p {
    text-align: center;
}

.back-sec .new {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

form.form-sec .logo + p {
    font-size: 23px;
    text-transform: uppercase;
    font-weight: 600;
}
.uploadImageBlock input + div {
    background: #fff;
}

form.form-sec button.sub {
    background: #393185;
    padding: 12px 30px;
    font-size: 18px;
    border: 0px;
    border-radius: 8px;
    color: #fff;
    text-transform: uppercase;
    margin: auto;
    display: block;
    margin-top: 20px;
}
.userPage {
    background:#d1eaf1 url(../../assets/images/bg4.jpg) no-repeat; 
    background-size: 100% auto;
    background-position: right;
    min-height: 100vh; 
    display: flex;
    align-items: center;
    justify-content: center;
} 
.user-list .users-blk {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.delete img{
    width: 11px !important;
    height: 25px;
}
.user-block {
    width: 25%;
    position: relative;
}
.delete{
    position: absolute !important;
    right: 25px;
    z-index: 99;
    top: 3px;
    display: none
}
.user-block:hover .delete{
    display: block;
}
.user-list .users-blk img {
    width: 120px;
    height: 120px;
    object-fit: contain;
    max-width: 100%;
}

.users-blk a {
    width: 20%;
}

.user-list .users-blk .user-block {
    text-align: center;
    width: 25%;
    margin-bottom: 20px;
    /* margin: 10px; */
}

.user-list .users-blk a p {
    font-weight: 600;
    color: #00843e;
    /* position: absolute; */
    margin-bottom: 0;
    margin: 0;
    top: -7px;
    /* width: 0; */
    background: #ebebeb;
    /* right: -7px; */
    color: #000;
    padding: 0px 10px;
    border-radius: 4px;
    /* vertical-align: middle; */
    /* display: flex; */
    text-align: center;
}
.user-list .users-blk  .user-block {
    min-height: 50px;
    position: relative;
}
.delete img {
    height: auto !important;
}
.sub-user {
    width: 70%;
    margin: auto;
    background: #ffffffe0;
    padding: 40px 70px;
    border-radius: 15px;
    box-shadow: 0px 0px 18px 0px #afafaf;
    min-height: 76vh;
    max-height: 85vh;
    overflow-y: scroll;
    position: relative;
}
.support {
    position: absolute;
    right: 10px;
    top: 10px;
}
.sub-user::-webkit-scrollbar {
    width: 5px;
    height: 100%;
    background: #393185;
}

.sub-user::-webkit-scrollbar-thumb {
    background: #e3e3e3;
}

.logo img {
    margin: auto;
    display: block;
}

.user-list h3 {
    text-align: center;
    margin-top: 40px;
}
.addFeild .dark-btn {
    margin-bottom: 0;
    line-height: 1;
}
.dark-btn {
    padding: 5px 10px;
    margin-left: auto;
    display: block;
    background: #393185;
    color: #fff;
    font-size: 18px;
    border: 0;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
}
.edit {
    background: #393185;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 5px 12px;
    border-radius: 9px;
    /* margin-left: auto; */
}
.edit[disabled] {
    background: gray;
    opacity: 0.5;
}
.edit svg {
    width: 20px;
    filter: invert();
}
.backArrow {
    float: left;
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.backArrow span {
    font-size: 16px;
}
ul.pagination {
    padding: 0;
    list-style: none; 
    width: max-content;
    margin-left: auto;
}

ul.pagination li {
    margin: 4px;
    display: inline-flex;
}
.search-bar {
    display: flex;
    align-items: center;
}

.search-bar input {
    padding: 5px 10px;
    margin-right: 10px;
    width: 78%;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #000;
}

.search-bar img {
    vertical-align: middle;
    width: 23px;
    filter: invert(1);
}
.wid-100{
    width: 100%
}
.wid-4 {
    width: 24% !important;
}
.wid-3 {
    width: 32% !important;
}
 

.form.show-details .input-feild > div input {
    border: 0px solid;
    background: transparent;
}
.form.show-details .input-feild > div p {
    margin: 0;
}

.form.show-details .input-feild > div label {
    color: green;
    margin-right: 10px;
}
.user-list  .inner-block {
    padding: 15px 9px;
    width: calc(92% - 18px);
    box-shadow: 0px 0px 14px 0px #b7b7b7;
    border-radius: 13px;
    background: #fff;
}   
@media(max-width: 1200px){
    .users-blk a {
        width: 25%;
    }
    .search-bar input {
        width: 70%;
    }
}

@media(max-width: 991px){
    .user-list .users-blk .user-block {
        width: 33%;}
    .search-bar input {
        width: 62%;
    }
    .users-blk a {
        width: 33%;
    }
    .wid-4 {
        width: 48% !important;
    }
    .modules ul li {
        width: 33%; 
    }
}
@media(max-width: 767px){
    .user-list .users-blk .user-block {
        width: 50%;
    }
    .wid-3 {
        width: 48% !important;
    }
    .modules ul li {
        width: 48%;
    }
    .sub-user {
        width: 80%; 
        padding: 40px 30px; 
    }
    .search-bar input {
        width: 52%;
    }
    .users-blk a {
        width: 50%;
    }
}

@media(max-width: 500px){
    .user-list .users-blk .user-block {
        width: 100%;
    }
    .form .d-flex.wid-100 {
        flex-direction: column;
    }
    .imageUpload {
        width: 100%;
        margin-bottom: 20px;
    }
    .search-bar {   
        flex-wrap: wrap;
    }
    .search-bar input {
        width: 100%;
        margin-bottom: 20px;
        margin: 0;
        margin-bottom: 20px;
    }   
    .removeBtn { 
        text-align: right;
    }
    .input-feild > div, .wid-4 {
        width: 100% !important;
    }
    .users-blk a {
        width: 100%;
    } 
    .user-list .users-blk .users-blk a > div > div{
        width: calc(100% - 20px);
    }
  
    .sub-user {
        width: 80%; 
        padding: 40px 20px; 
    }
}